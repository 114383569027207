<template>
  <div class="integrate-page">
    <div class="top">
      <el-date-picker
        v-model="searchForm.startDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择开始时间"
        size="small"
      ></el-date-picker>
      <el-date-picker
        v-model="searchForm.endDate"
        type="date"
        style="margin-left:10px"
        size="small"
        value-format="yyyy-MM-dd"
        placeholder="选择结束时间"
      ></el-date-picker>
      <el-select
        v-model="searchForm.type"
        size="small"
        style="margin-left:10px"
        placeholder="请选择查询类型"
      >
        <el-option label="全部" :value="''"></el-option>
        <el-option label="归属地查询" :value="1"></el-option>
        <el-option label="手机查询" :value="2"></el-option>
      </el-select>
      <el-button
        type="primary"
        size="mini"
        style=" margin-left:10px"
        @click="searchFun"
        :loading="loading"
      >
        查询
      </el-button>
      <el-button
        class="el-icon-refresh-left"
        size="mini"
        style=" margin-left:10px"
        @click="refresh"
        :loading="loading"
      >
        刷新
      </el-button>
    </div>
    <div class="child" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="list"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="tgid" label="Telegram uid"></el-table-column>
        <el-table-column prop="created_at" label="提交时间"></el-table-column>
        <el-table-column prop="type" label="查询类型" width="160">
          <template slot-scope="scope">
            <!-- <span v-if="scope.row.type == 1">归属地查询</span>
            <span v-else-if="scope.row.type == 2">手机查询</span> -->
            <el-link v-if="scope.row.type == 1" type="primary">
              归属地查询
            </el-link>
            <el-link v-else-if="scope.row.type == 2" type="success">
              手机查询
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="residual_integral"
          label="剩余积分"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template>
            <el-button type="primary" size="mini" @click="viewPhone">
              查看手机
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :margin="0"
        :sizesArr="[30, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>

    <!-- 手机详情弹框 -->
    <userDetail :placeVisible="placeVisible" @closePlace="closePlace" />
  </div>
</template>

<script>
import { integral } from '@/api/dropCheck/integrate'
export default {
  name: 'Integrate',
  data() {
    return {
      loading: false,
      list: [],
      current: 1,
      limit: 30,
      total: 0,
      searchForm: {
        endDate: '',
        startDate: '',
        type: ''
      },
      placeVisible: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    this.getPage()
  },
  methods: {
    viewPhone() {
      this.$confirm('此操作将消耗积分, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.placeVisible = true
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 关闭弹框
    closePlace() {
      this.placeVisible = false
    },
    searchFun() {
      this.current = 1
      this.getPage()
    },
    refresh() {
      this.getPage()
    },
    getPage() {
      this.loading = true
      integral(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.integrate-page {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  .top {
    padding: 10px;
    background: white;
  }
  .child {
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 1px;
    background: white;
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
